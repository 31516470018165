/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

.PanZoomCanvas {
  display: block;
  user-select: none;
  cursor: grab;

  /* Centre canvas horizontally (so it resizes from centre)  */
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  width: auto;
  min-width: 100%;
}

.PanZoomCanvas:active {
  cursor: grabbing;
}
