/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

@keyframes loader {
  0% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(100%, 0%);
  }
}

#container {
  display: relative;
  min-width: 10vw;
  min-height: 10vh;
  background-color: var(--greyH);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem;
  border-top: solid #4c4c4c 1px;
  border-left: solid 1px #383838;
}

#app-name {
  background-color: var(--greyC);
  background-image: repeating-linear-gradient(
    323deg,
    hsla(0, 0%, 0%, 0.4),
    transparent 7rem
  );
  background-clip: text;
  color: transparent;
  font-weight: 900;
  font-size: 1.9rem;
  text-transform: uppercase;
  margin: 0.8rem 0;
}

#app-ouster-inc {
  color: var(--greyC);
  font-size: 0.8rem;
  font-weight: 600;
}

#loader-container {
  position: relative;
  height: 3px;
  width: 7.5rem;
  background-color: var(--greyF);
  overflow: hidden;
  margin-top: 0.5rem;
}
#loader {
  position: absolute;
  bottom: 0;
  height: 3px;
  width: 100%;
  background-color: var(--greyD);
  animation: 3s loader infinite;
}
