/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/
/* See .jsoneditor-search button in dev tools, the icons are backgrounds. We could change them. */

.perception-settings-editor .jsoneditor {
  border: none;
}

.perception-settings-editor .jsoneditor button {
  display: inline-block;
}

.perception-settings-editor .jsoneditor-menu {
  background-color: var(--greyF);
  border-bottom: thin solid var(--greyF);
}

tr.jsoneditor-highlight,
tr.jsoneditor-selected {
  background-color: var(--greyF);
}

.perception-settings-editor .jsoneditor-mode-form,
.perception-settings-editor .jsoneditor-mode-tree {
  position: absolute;
  overflow-y: auto;
}

.perception-settings-editor .jsoneditor-frame,
.perception-settings-editor .jsoneditor input {
  background-color: var(--greyG);
}

.perception-settings-editor .jsoneditor input,
.perception-settings-editor .jsoneditor-results {
  color: var(--greyB);
}

.perception-settings-editor .jsoneditor-tree {
  background-color: var(--greyH);
}

/* Hide {N} and [N] field counts */
.perception-settings-editor .jsoneditor-tree .jsoneditor-object,
.perception-settings-editor .jsoneditor-tree .jsoneditor-array {
  display: none;
}

.perception-settings-editor .jsoneditor-tree button.jsoneditor-button:focus {
  background-color: var(--greyF);
  outline: var(--greyG) solid 1px;
}

.perception-settings-editor div.jsoneditor-field.jsoneditor-highlight-active,
.perception-settings-editor div.jsoneditor-value.jsoneditor-highlight-active {
  background-color: var(--greyE);
  border: var(--greyF) solid 1px;
}

.perception-settings-editor
  div.jsoneditor-field.jsoneditor-highlight:not(.jsoneditor-highlight-active),
.perception-settings-editor
  div.jsoneditor-value.jsoneditor-highlight:not(.jsoneditor-highlight-active) {
  background-color: var(--greyF);
  border: var(--greyG) solid 1px;
}

.perception-settings-editor div.jsoneditor-readonly {
  color: var(--greyC);
}

.perception-settings-editor div.jsoneditor-value {
  color: var(--greyB);
}

.perception-settings-editor div.jsoneditor-field {
  color: var(--greyB);
}

.perception-settings-editor
  div.jsoneditor-value.jsoneditor-number[contenteditable='true']:hover,
.perception-settings-editor
  div.jsoneditor-value.jsoneditor-string[contenteditable='true']:hover,
.perception-settings-editor
  div.jsoneditor-value.jsoneditor-boolean[contenteditable='true']:hover,
.perception-settings-editor
  div.jsoneditor-value.jsoneditor-null[contenteditable='true']:hover {
  background-color: var(--greyG);
  border-color: var(--greyG);
}

.perception-settings-editor
  div.jsoneditor-value.jsoneditor-number[contenteditable='true']:focus,
.perception-settings-editor
  div.jsoneditor-value.jsoneditor-string[contenteditable='true']:focus,
.perception-settings-editor
  div.jsoneditor-value.jsoneditor-boolean[contenteditable='true']:focus,
.perception-settings-editor
  div.jsoneditor-value.jsoneditor-null[contenteditable='true']:focus {
  background-color: var(--greyF);
  border-color: var(--greyF);
}

.perception-settings-editor div.jsoneditor-field[contenteditable='true']:hover {
  background-color: var(--greyG);
  border-color: var(--greyG);
}

.perception-settings-editor div.jsoneditor-field[contenteditable='true']:focus {
  background-color: var(--greyF);
  border-color: var(--greyF);
}

.perception-settings-editor div.jsoneditor-value.jsoneditor-string {
  color: #98c379;
}

.perception-settings-editor div.jsoneditor-value.jsoneditor-number {
  color: #ee422e;
}

.perception-settings-editor div.jsoneditor-value.jsoneditor-boolean {
  color: #ff8c00;
}
