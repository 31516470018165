/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

/* Wrapping */
.Tooltip-Wrapper:hover .Tooltip-Tip {
  opacity: 1;
  /* Make it appear slow, fade fast */
  transition-duration: 0.2s;
  transition-delay: 0.8s;
}

/* Absolute positioning */
.Tooltip-Tip {
  --tooltip-background: var(--greyF);
  --tooltip-margin: 1rem;
  --tooltip-arrow-size: 6px;
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  color: var(--greyC);
  background: var(--tooltip-background);
  font-size: var(--r3);
  font-family: sans-serif;
  line-height: 1;
  z-index: 2;
  white-space: nowrap;
  opacity: 0;

  /* 
    Hovering the child doesn't trigger the 
    parent's hover. We need this so the tooltip is only 
    visible when hovering the wrapped content, not the 
    tooltip itself.
  */
  pointer-events: none;

  /* Transition */
  transition: opacity cubic-bezier(0.77, 0, 0.175, 1) 0.0025s;
}

/* CSS border triangles */
.Tooltip-Tip::before {
  content: ' ';
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

/* Absolute positioning */
.Tooltip-Tip.top {
  top: calc(var(--tooltip-margin) * -2);
}
/* CSS border triangles */
.Tooltip-Tip.top::before {
  top: 100%;
  border-top-color: var(--tooltip-background);
}

/* Absolute positioning */
.Tooltip-Tip.right {
  left: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.right::before {
  left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: var(--tooltip-background);
}

/* Absolute positioning */
.Tooltip-Tip.bottom {
  bottom: calc(var(--tooltip-margin) * -2);
}
/* CSS border triangles */
.Tooltip-Tip.bottom::before {
  bottom: 100%;
  border-bottom-color: var(--tooltip-background);
}

/* Absolute positioning */
.Tooltip-Tip.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.left::before {
  left: auto;
  right: calc(var(--tooltip-arrow-size) * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: var(--tooltip-background);
}
