@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600;700&display=swap);
/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

@-webkit-keyframes loader {
  0% {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
  }
  100% {
    -webkit-transform: translate(100%, 0%);
            transform: translate(100%, 0%);
  }
}

@keyframes loader {
  0% {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
  }
  100% {
    -webkit-transform: translate(100%, 0%);
            transform: translate(100%, 0%);
  }
}

#container {
  display: relative;
  min-width: 10vw;
  min-height: 10vh;
  background-color: var(--greyH);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem;
  border-top: solid #4c4c4c 1px;
  border-left: solid 1px #383838;
}

#app-name {
  background-color: var(--greyC);
  background-image: repeating-linear-gradient(
    323deg,
    hsla(0, 0%, 0%, 0.4),
    transparent 7rem
  );
  -webkit-background-clip: text;
          background-clip: text;
  color: transparent;
  font-weight: 900;
  font-size: 1.9rem;
  text-transform: uppercase;
  margin: 0.8rem 0;
}

#app-ouster-inc {
  color: var(--greyC);
  font-size: 0.8rem;
  font-weight: 600;
}

#loader-container {
  position: relative;
  height: 3px;
  width: 7.5rem;
  background-color: var(--greyF);
  overflow: hidden;
  margin-top: 0.5rem;
}
#loader {
  position: absolute;
  bottom: 0;
  height: 3px;
  width: 100%;
  background-color: var(--greyD);
  -webkit-animation: 3s loader infinite;
          animation: 3s loader infinite;
}

/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

.button {
  width: 24px;
  height: 24px;
  border: 3px solid var(--greyB);
  border-radius: 50%;
  cursor: pointer;

  /* Transform to preserve centering */
  -webkit-transform-origin: center;
          transform-origin: center;
  /* Width and height match that for play button (18px) : 24px * 0.83 = 20px*/
  -webkit-transform: scale(0.833);
          transform: scale(0.833);
  transition: -webkit-filter 0.2s ease;
  transition: filter 0.2s ease;
  transition: filter 0.2s ease, -webkit-filter 0.2s ease;
}

.disabled .button {
  cursor: not-allowed;
  -webkit-filter: brightness(0.3);
          filter: brightness(0.3);
  will-change: filter;
}

.inner {
  background-color: var(--error);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  will-change: transform;

  -webkit-transform-origin: center;

          transform-origin: center;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
  -webkit-transform: scale(0.85);
          transform: scale(0.85);
}

.button.active .inner {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  border-radius: 12%;
}

/* Recording blinker */
.recording-blinker {
  -webkit-animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
          animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}
@-webkit-keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Marquee recording name */
.marquee {
  width: 8rem; /* Width of marquee "window" must match the width of p elements*/
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}

.marquee div {
  display: table-row;
  white-space: nowrap;
  padding-left: 100%;
  -webkit-animation: marquee 10s linear infinite;
          animation: marquee 10s linear infinite; /* Time must be adjusted based on total width of scrolled elements*/
}

.marquee div span {
  width: 8rem; /* Width of p elements must match the width of marquee "window"*/
  padding-left: 8rem; /* Padding determines space between scrolled elements */
  display: table-cell;
}

/* Make it move */
@-webkit-keyframes marquee {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
  }
}
@keyframes marquee {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
  }
}

/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

/* Wrapping */
.Tooltip-Wrapper:hover .Tooltip-Tip {
  opacity: 1;
  /* Make it appear slow, fade fast */
  transition-duration: 0.2s;
  transition-delay: 0.8s;
}

/* Absolute positioning */
.Tooltip-Tip {
  --tooltip-background: var(--greyF);
  --tooltip-margin: 1rem;
  --tooltip-arrow-size: 6px;
  position: absolute;
  border-radius: 4px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  padding: 6px;
  color: var(--greyC);
  background: var(--tooltip-background);
  font-size: var(--r3);
  font-family: sans-serif;
  line-height: 1;
  z-index: 2;
  white-space: nowrap;
  opacity: 0;

  /* 
    Hovering the child doesn't trigger the 
    parent's hover. We need this so the tooltip is only 
    visible when hovering the wrapped content, not the 
    tooltip itself.
  */
  pointer-events: none;

  /* Transition */
  transition: opacity cubic-bezier(0.77, 0, 0.175, 1) 0.0025s;
}

/* CSS border triangles */
.Tooltip-Tip::before {
  content: ' ';
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

/* Absolute positioning */
.Tooltip-Tip.top {
  top: calc(var(--tooltip-margin) * -2);
}
/* CSS border triangles */
.Tooltip-Tip.top::before {
  top: 100%;
  border-top-color: var(--tooltip-background);
}

/* Absolute positioning */
.Tooltip-Tip.right {
  left: calc(100% + var(--tooltip-margin));
  top: 50%;
  -webkit-transform: translateX(0) translateY(-50%);
          transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.right::before {
  left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  -webkit-transform: translateX(0) translateY(-50%);
          transform: translateX(0) translateY(-50%);
  border-right-color: var(--tooltip-background);
}

/* Absolute positioning */
.Tooltip-Tip.bottom {
  bottom: calc(var(--tooltip-margin) * -2);
}
/* CSS border triangles */
.Tooltip-Tip.bottom::before {
  bottom: 100%;
  border-bottom-color: var(--tooltip-background);
}

/* Absolute positioning */
.Tooltip-Tip.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  -webkit-transform: translateX(0) translateY(-50%);
          transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.left::before {
  left: auto;
  right: calc(var(--tooltip-arrow-size) * -2);
  top: 50%;
  -webkit-transform: translateX(0) translateY(-50%);
          transform: translateX(0) translateY(-50%);
  border-left-color: var(--tooltip-background);
}

/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

.labelsContainer {
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

#Layout {
  display: grid;
  grid-template-areas:
    'header header header'
    'pane-left image pane-right'
    'pane-left content pane-right'
    'pane-left timeline pane-right'
    'footer footer footer';
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto 1fr auto;
  height: 100vh;
  overflow: hidden;
}

#Header {
  z-index: 20;
  grid-area: header;
}

#Footer {
  grid-area: footer;
}

#Pane-left {
  grid-area: pane-left;
  overflow-y: hidden;
}

#Pane-right {
  grid-area: pane-right;
  overflow-y: hidden;
}

#Image2D {
  grid-area: image;
  overflow: hidden;
  resize: vertical;
  max-height: 30vh;
}

#Timeline {
  grid-area: timeline;
}

#Content {
  position: relative;
  touch-action: none;
  flex: 1 1;
  grid-area: content;
}

.modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  z-index: 3;
  background-color: hsl(0deg 0% 0% / 20%);
}

canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  box-shadow: inset 0px 0px 10px 0px hsl(0deg 0% 0% / 10%);
}

/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/
/* See .jsoneditor-search button in dev tools, the icons are backgrounds. We could change them. */

.perception-settings-editor .jsoneditor {
  border: none;
}

.perception-settings-editor .jsoneditor button {
  display: inline-block;
}

.perception-settings-editor .jsoneditor-menu {
  background-color: var(--greyF);
  border-bottom: thin solid var(--greyF);
}

tr.jsoneditor-highlight,
tr.jsoneditor-selected {
  background-color: var(--greyF);
}

.perception-settings-editor .jsoneditor-mode-form,
.perception-settings-editor .jsoneditor-mode-tree {
  position: absolute;
  overflow-y: auto;
}

.perception-settings-editor .jsoneditor-frame,
.perception-settings-editor .jsoneditor input {
  background-color: var(--greyG);
}

.perception-settings-editor .jsoneditor input,
.perception-settings-editor .jsoneditor-results {
  color: var(--greyB);
}

.perception-settings-editor .jsoneditor-tree {
  background-color: var(--greyH);
}

/* Hide {N} and [N] field counts */
.perception-settings-editor .jsoneditor-tree .jsoneditor-object,
.perception-settings-editor .jsoneditor-tree .jsoneditor-array {
  display: none;
}

.perception-settings-editor .jsoneditor-tree button.jsoneditor-button:focus {
  background-color: var(--greyF);
  outline: var(--greyG) solid 1px;
}

.perception-settings-editor div.jsoneditor-field.jsoneditor-highlight-active,
.perception-settings-editor div.jsoneditor-value.jsoneditor-highlight-active {
  background-color: var(--greyE);
  border: var(--greyF) solid 1px;
}

.perception-settings-editor
  div.jsoneditor-field.jsoneditor-highlight:not(.jsoneditor-highlight-active),
.perception-settings-editor
  div.jsoneditor-value.jsoneditor-highlight:not(.jsoneditor-highlight-active) {
  background-color: var(--greyF);
  border: var(--greyG) solid 1px;
}

.perception-settings-editor div.jsoneditor-readonly {
  color: var(--greyC);
}

.perception-settings-editor div.jsoneditor-value {
  color: var(--greyB);
}

.perception-settings-editor div.jsoneditor-field {
  color: var(--greyB);
}

.perception-settings-editor
  div.jsoneditor-value.jsoneditor-number[contenteditable='true']:hover,
.perception-settings-editor
  div.jsoneditor-value.jsoneditor-string[contenteditable='true']:hover,
.perception-settings-editor
  div.jsoneditor-value.jsoneditor-boolean[contenteditable='true']:hover,
.perception-settings-editor
  div.jsoneditor-value.jsoneditor-null[contenteditable='true']:hover {
  background-color: var(--greyG);
  border-color: var(--greyG);
}

.perception-settings-editor
  div.jsoneditor-value.jsoneditor-number[contenteditable='true']:focus,
.perception-settings-editor
  div.jsoneditor-value.jsoneditor-string[contenteditable='true']:focus,
.perception-settings-editor
  div.jsoneditor-value.jsoneditor-boolean[contenteditable='true']:focus,
.perception-settings-editor
  div.jsoneditor-value.jsoneditor-null[contenteditable='true']:focus {
  background-color: var(--greyF);
  border-color: var(--greyF);
}

.perception-settings-editor div.jsoneditor-field[contenteditable='true']:hover {
  background-color: var(--greyG);
  border-color: var(--greyG);
}

.perception-settings-editor div.jsoneditor-field[contenteditable='true']:focus {
  background-color: var(--greyF);
  border-color: var(--greyF);
}

.perception-settings-editor div.jsoneditor-value.jsoneditor-string {
  color: #98c379;
}

.perception-settings-editor div.jsoneditor-value.jsoneditor-number {
  color: #ee422e;
}

.perception-settings-editor div.jsoneditor-value.jsoneditor-boolean {
  color: #ff8c00;
}

/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/
 
.floating-input {
  position: relative;
  margin-top: var(--r6);
  margin-bottom: var(--r2);
}
.floating-input .input:not(:-ms-input-placeholder) ~ .label  /* Input has a value */ {
  transform: translateY(-35px) translateX(calc(-1 * var(--r2))) scale(0.75);
  -ms-transition: transform 200ms, color 200ms;
  transition: transform 200ms, color 200ms;
}
.floating-input .input:focus ~ .label,  /* Input has focus */
.floating-input .input:not(:placeholder-shown) ~ .label  /* Input has a value */ {
  -webkit-transform: translateY(-35px) translateX(calc(-1 * var(--r2))) scale(0.75);
          transform: translateY(-35px) translateX(calc(-1 * var(--r2))) scale(0.75);
  transition: color 200ms, -webkit-transform 200ms;
  transition: transform 200ms, color 200ms;
  transition: transform 200ms, color 200ms, -webkit-transform 200ms;
}

.floating-input .input:not(:-ms-input-placeholder) ~ .label {
  color: var(--greyD);
  fill: var(--greyD);
}

.floating-input .input:not(:placeholder-shown) ~ .label {
  color: var(--greyD);
  fill: var(--greyD);
}
.floating-input .input:focus ~ .label {
  color: var(--greyC);
  fill: var(--greyC);
}

.floating-input .input {
  height: 100%;
  width: 100%;
  background-color: var(--greyI);
  border-radius: 5px;
  box-sizing: border-box;
  outline: 0;
  padding: var(--r2) var(--r2);
}

.floating-input .input:disabled {
  background-color: var(--greyH);
  color: var(--greyD);
  cursor: not-allowed;
}

.floating-input .placeholder {
  color: var(--greyD);
  fill: var(--greyD);
  font-family: sans-serif;
  /* match left padding */
  left: var(--r2);
  pointer-events: none;
  position: absolute;
  -webkit-transform-origin: 0 50%;
          transform-origin: 0 50%;
  transition: color 200ms, -webkit-transform 200ms;
  transition: transform 200ms, color 200ms;
  transition: transform 200ms, color 200ms, -webkit-transform 200ms;
  margin: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

@-webkit-keyframes spinner {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spinner {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border-top: 2px solid var(--greyB);
  border-right: 2px solid transparent;
  -webkit-animation: spinner 0.6s linear infinite;
          animation: spinner 0.6s linear infinite;
}

/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

:root {
  --table-min-width: 1150px;
}

.diagnostics table {
  border: none;
  font-family: arial, sans-serif;
  border-collapse: collapse;
}

.diagnostics-tables {
  position: absolute;
  overflow-y: auto;
}

.diagnostics .table-type-section {
  display: flex;
  flex-direction: row;
}
.diagnostics .table-type-section:not(:first-child) {
  margin-top: var(--r6);
}

.diagnostics td,
.diagnostics th {
  border-top: none;
  text-align: center;
  padding: var(--r2);
}

.diagnostics tr {
  border: none;
  cursor: pointer;
  justify-content: flex-start;
}

.diagnostics td:not(.expanded-row-content),
.diagnostics tr {
  display: grid;
  grid-template-columns: 0.15fr 0.5fr 1fr 0.5fr 0.5fr 0.5fr 0.25fr 0;
}

.diagnostics tr:not(.table-header) {
  color: var(--greyC);
  background-color: var(--greyI);
}

.diagnostics .lastOccured,
.diagnostics .firstOccured,
.diagnostics .alertCode {
  color: var(--greyD);
}

.diagnostics tr:not(.table-header):hover {
  color: var(--hover);
  background-color: var(--greyH);
}
.diagnostics tr.active {
  color: var(--hover);
  background-color: var(--greyH);
}
.diagnostics tr.active:hover {
  -webkit-filter: brightness(1.1);
          filter: brightness(1.1);
}
.diagnostics tr:hover td button {
  -webkit-filter: brightness(1.1);
          filter: brightness(1.1);
}

.diagnostics .expanded-row-content {
  border-top: none;
  padding: 0 8px;
  /* Add a max-height we'll never reach to emulate transitioning to height: auto */
  max-height: 100vh;
  overflow: hidden;
  opacity: 1;
  display: grid;
  grid-column: 1/-1;
  justify-content: flex-start;
  transition: max-height ease-in-out, opacity ease-in-out;
  transition-duration: 0.75s;
}

.diagnostics .hide-row {
  max-height: 0px;
  opacity: 0;
  transition-duration: 0.4s;
}

.diagnostics .warning {
  color: var(--warning);
}
.diagnostics .error {
  color: var(--error);
}
.diagnostics .fatal {
  color: var(--fatal);
}

#diagnostics-layout {
  background: var(--greyI);
  width: 100vw;
  height: 90vh;
  display: grid;
  grid-template-areas: 'content pane-right';
  grid-template-columns: 1fr var(--r80);
  overflow: hidden;
}

#diagnostics-pane {
  grid-area: pane-right;
  overflow-y: hidden;
  background-color: var(--greyH);
  width: var(--r80);
}

/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

.floating-text-area {
  position: relative;
  margin-top: var(--r6);
  margin-bottom: var(--r2);
}
.floating-text-area .textarea:not(:-ms-input-placeholder) ~ .label  /* Input has a value */ {
  transform: translateY(-35px) translateX(calc(-1 * var(--r2))) scale(0.85);
  -ms-transition: transform 200ms, color 200ms;
  transition: transform 200ms, color 200ms;
}
.floating-text-area .textarea:focus ~ .label,  /* Input has focus */
  .floating-text-area .textarea:not(:placeholder-shown) ~ .label  /* Input has a value */ {
  -webkit-transform: translateY(-35px) translateX(calc(-1 * var(--r2))) scale(0.85);
          transform: translateY(-35px) translateX(calc(-1 * var(--r2))) scale(0.85);
  transition: color 200ms, -webkit-transform 200ms;
  transition: transform 200ms, color 200ms;
  transition: transform 200ms, color 200ms, -webkit-transform 200ms;
}

.floating-text-area .textarea:not(:-ms-input-placeholder) ~ .label {
  color: var(--greyD);
}

.floating-text-area .textarea:not(:placeholder-shown) ~ .label {
  color: var(--greyD);
}
.floating-text-area .textarea:focus ~ .label {
  color: var(--greyC);
}

.floating-text-area .textarea {
  height: 100%;
  width: 100%;
  background-color: var(--greyI);
  border-radius: 5px;
  box-sizing: border-box;
  outline: 0;
  padding: var(--r2) var(--r2);
}

.floating-text-area .textarea:disabled {
  background-color: var(--greyH);
  color: var(--greyD);
  cursor: not-allowed;
}

.floating-text-area .placeholder {
  color: var(--greyD);
  font-family: sans-serif;
  /* match left padding */
  left: var(--r2);
  pointer-events: none;
  position: absolute;
  -webkit-transform-origin: 0 50%;
          transform-origin: 0 50%;
  transition: color 200ms, -webkit-transform 200ms;
  transition: transform 200ms, color 200ms;
  transition: transform 200ms, color 200ms, -webkit-transform 200ms;
  margin: 0;
  top: 18px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

.PanZoomCanvas {
  display: block;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: grab;

  /* Centre canvas horizontally (so it resizes from centre)  */
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);

  width: auto;
  min-width: 100%;
}

.PanZoomCanvas:active {
  cursor: grabbing;
}

/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

input[type='range'] {
  -webkit-appearance: none;
  margin: 0;
  width: 100%;
  background: transparent;
  cursor: pointer;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 4px;
  height: 20px;
  background: var(--greyC);
  cursor: pointer;
}

input[type='range']:focus::-webkit-slider-thumb {
  background: var(--greyB);
}

/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

@font-face {
  font-family: 'Spezia';
  font-style: normal;
  font-weight: 100;
  src: url(/static/media/Spezia-Thin.f2d6d76b.otf) format('opentype');
}

@font-face {
  font-family: 'Spezia';
  font-style: italic;
  font-weight: 100;
  src: url(/static/media/Spezia-ThinItalic.8aa81119.otf) format('opentype');
}

@font-face {
  font-family: 'Spezia';
  font-style: normal;
  font-weight: 200;
  src: url(/static/media/Spezia-Light.bbfd1a54.otf) format('opentype');
}

@font-face {
  font-family: 'Spezia';
  font-style: italic;
  font-weight: 200;
  src: url(/static/media/Spezia-LightItalic.a4e13fc5.otf) format('opentype');
}

@font-face {
  font-family: 'Spezia';
  font-style: normal;
  font-weight: 300;
  src: url(/static/media/Spezia-Book.07b43d10.otf) format('opentype');
}

@font-face {
  font-family: 'Spezia';
  font-style: italic;
  font-weight: 300;
  src: url(/static/media/Spezia-BookItalic.6b6faece.otf) format('opentype');
}

@font-face {
  font-family: 'Spezia';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Spezia-Regular.f37f42a3.otf) format('opentype');
}

@font-face {
  font-family: 'Spezia';
  font-style: italic;
  font-weight: 400;
  src: url(/static/media/Spezia-RegularItalic.11b78db8.otf) format('opentype');
}

@font-face {
  font-family: 'Spezia';
  font-style: normal;
  font-weight: 500;
  src: url(/static/media/Spezia-Medium.789a5520.otf) format('opentype');
}

@font-face {
  font-family: 'Spezia';
  font-style: italic;
  font-weight: 500;
  src: url(/static/media/Spezia-MediumItalic.f2a7a896.otf) format('opentype');
}

@font-face {
  font-family: 'Spezia';
  font-style: normal;
  font-weight: 600;
  src: url(/static/media/Spezia-SemiBold.2b25111b.otf) format('opentype');
}

@font-face {
  font-family: 'Spezia';
  font-style: italic;
  font-weight: 600;
  src: url(/static/media/Spezia-SemiBoldItalic.89ddc720.otf) format('opentype');
}

@font-face {
  font-family: 'Spezia';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/Spezia-Bold.6befa010.otf) format('opentype');
}

@font-face {
  font-family: 'Spezia';
  font-style: italic;
  font-weight: 700;
  src: url(/static/media/Spezia-BoldItalic.2350bd9c.otf) format('opentype');
}

/* fallback */

body {
  margin: 0;
  font-family: 'Spezia', 'Roboto', 'Arial', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  font-family: var(--sans);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Spezia', 'Roboto Mono', Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-family: var(--mono);
}

/* Basic reset  */
html {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

svg.Logo {
  fill: white;
}

:root {
  --sans: 'Spezia', 'Roboto', 'Arial', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  --mono: 'Spezia', 'Roboto Mono', Menlo, Monaco, Consolas, 'Courier New',
    monospace;

  /* color scheme */
  --greyA: hsl(240, 0%, 95%);
  --greyB: hsl(240, 2%, 78%);
  --greyC: hsl(240, 3%, 62%);
  --greyD: hsl(240, 4%, 45%);
  --greyE: hsl(240, 5%, 35%);
  --greyF: hsl(240, 5%, 23%);
  --greyG: hsl(240, 6%, 20%);
  --greyH: hsl(240, 6%, 16%);
  --greyI: hsl(240, 6%, 14%);
  --greyJ: hsl(240, 7%, 12%);

  --primMilkyWay: #ffffff;
  --primCrater: #e3e3e3;
  --primGravity: #c6c6c6;
  --primGalaxy: #3c3c3c;
  --primBlackHole: #000000;
  --secIndigo: #031f50;
  --secBlue: #35459c;
  --secMagenta: #b43996;

  --active: #70b339;
  --unknown: #ccc91f;
  --inactive: #c21f2f;

  --info: #838ec2;
  --success: #a9b1a9;
  --warning: #e0ac5d;
  --error: #b67584;
  --fatal: #69313e;
  /* previous colours: */
  /* more vibrant: #d4211e; */
  --critical: #bf302e;
  --criticalHover: #db4644;

  --ideal: #607d8b;
  --highlighted: #ff9800;
  --selected: #ff5722;

  --transparentBg: hsla(240, 5%, 11%, 0.745);

  --r1: 0.25rem;
  --r1_5: 0.375rem;
  --r2: 0.5rem;
  --r2_5: 0.625rem;
  --r3: 0.75rem;
  --r3_5: 0.875rem;
  --r4: 1rem;
  --r5: 1.25rem;
  --r6: 1.5rem;
  --r7: 1.75rem;
  --r8: 2rem;
  --r9: 2.25rem;
  --r10: 2.5rem;
  --r16: 4rem;
  --r24: 6rem;
  --r32: 8rem;
  --r64: 16rem;
  --r80: 20rem;

  --border-radius: 3px;

  --header-h: 3rem;
  --sub-header-h: 4rem;
  --footer-h: 1.5rem;
  --scrollbar-w: 15px;
}

::selection {
  color: hsl(240, 6%, 16%);
  color: var(--greyH);
  background: hsl(240, 2%, 78%);
  background: var(--greyB);
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 15px;
  width: var(--scrollbar-w);
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: hsl(240, 5%, 23%);
  background-color: var(--greyF);
  border-radius: 30px;
  border: 5px solid transparent;
  background-clip: content-box;
  outline-offset: -20px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: hsl(240, 5%, 35%);
  background-color: var(--greyE);
}
::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

:focus {
  outline: none;
}

/* class utilities */
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.cursor-pointer {
  cursor: pointer;
}
.overflow-y {
  overflow-y: overlay;
}
.overflow-hidden {
  overflow: hidden;
}
.no-select {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}

/* dfg */
.text-xxs {
  font-size: 0.625rem;
  font-size: var(--r2_5);
}
.text-xs {
  font-size: 0.75rem;
  font-size: var(--r3);
}
.text-sm {
  font-size: 0.875rem;
  font-size: var(--r3_5);
}
.text-base {
  font-size: 1rem;
  font-size: var(--r4);
}
.text-lg {
  font-size: 1.25rem;
  font-size: var(--r5);
}
.text-xl {
  font-size: 1.5rem;
  font-size: var(--r6);
}
.text-2xl {
  font-size: 1.75rem;
  font-size: var(--r7);
}
.text-3xl {
  font-size: 2.25rem;
  font-size: var(--r9);
}

/* padding */

.p-0 {
  padding: 0;
}
.p-0_5 {
  padding: 4px;
}
.p-1 {
  padding: 0.375rem;
  padding: var(--r1_5);
}
.p-2 {
  padding: 0.5rem;
  padding: var(--r2);
}
.p-3 {
  padding: 0.75rem;
  padding: var(--r3);
}
.p-4 {
  padding: 1rem;
  padding: var(--r4);
}
.px-1 {
  padding-left: 0.375rem;
  padding-left: var(--r1_5);
  padding-right: 0.375rem;
  padding-right: var(--r1_5);
}
.px-2 {
  padding-left: 0.5rem;
  padding-left: var(--r2);
  padding-right: 0.5rem;
  padding-right: var(--r2);
}
.px-3 {
  padding-left: 0.75rem;
  padding-left: var(--r3);
  padding-right: 0.75rem;
  padding-right: var(--r3);
}
.px-4 {
  padding-left: 1rem;
  padding-left: var(--r4);
  padding-right: 1rem;
  padding-right: var(--r4);
}
.px-4 {
  padding-left: 1rem;
  padding-left: var(--r4);
  padding-right: 1rem;
  padding-right: var(--r4);
}
.px-5 {
  padding-left: 1.25rem;
  padding-left: var(--r5);
  padding-right: 1.25rem;
  padding-right: var(--r5);
}
.px-6 {
  padding-left: 1.5rem;
  padding-left: var(--r6);
  padding-right: 1.5rem;
  padding-right: var(--r6);
}
.px-7 {
  padding-left: 1.75rem;
  padding-left: var(--r7);
  padding-right: 1.75rem;
  padding-right: var(--r7);
}
.py-1 {
  padding-top: 0.375rem;
  padding-top: var(--r1_5);
  padding-bottom: 0.375rem;
  padding-bottom: var(--r1_5);
}
.py-2 {
  padding-top: 0.5rem;
  padding-top: var(--r2);
  padding-bottom: 0.5rem;
  padding-bottom: var(--r2);
}
.py-3 {
  padding-top: 0.75rem;
  padding-top: var(--r3);
  padding-bottom: 0.75rem;
  padding-bottom: var(--r3);
}
.py-4 {
  padding-top: 1rem;
  padding-top: var(--r4);
  padding-bottom: 1rem;
  padding-bottom: var(--r4);
}
.py-5 {
  padding-top: 1.25rem;
  padding-top: var(--r5);
  padding-bottom: 1.25rem;
  padding-bottom: var(--r5);
}
.py-6 {
  padding-top: 1.5rem;
  padding-top: var(--r6);
  padding-bottom: 1.5rem;
  padding-bottom: var(--r6);
}
.py-7 {
  padding-top: 1.75rem;
  padding-top: var(--r7);
  padding-bottom: 1.75rem;
  padding-bottom: var(--r7);
}

/* margin */
.m-1 {
  margin: 0.375rem;
  margin: var(--r1_5);
}
.m-2 {
  margin: 0.5rem;
  margin: var(--r2);
}
.m-3 {
  margin: 0.75rem;
  margin: var(--r3);
}
.m-4 {
  margin: 1rem;
  margin: var(--r4);
}
.m-5 {
  margin: 1.25rem;
  margin: var(--r5);
}
.m-6 {
  margin: 1.5rem;
  margin: var(--r6);
}

.mx-1 {
  margin-left: 0.375rem;
  margin-left: var(--r1_5);
  margin-right: 0.375rem;
  margin-right: var(--r1_5);
}
.mx-2 {
  margin-left: 0.5rem;
  margin-left: var(--r2);
  margin-right: 0.5rem;
  margin-right: var(--r2);
}
.mx-3 {
  margin-left: 0.75rem;
  margin-left: var(--r3);
  margin-right: 0.75rem;
  margin-right: var(--r3);
}
.mx-4 {
  margin-left: 1rem;
  margin-left: var(--r4);
  margin-right: 1rem;
  margin-right: var(--r4);
}
.mx-5 {
  margin-left: 1.25rem;
  margin-left: var(--r5);
  margin-right: 1.25rem;
  margin-right: var(--r5);
}
.mx-6 {
  margin-left: 1.5rem;
  margin-left: var(--r6);
  margin-right: 1.5rem;
  margin-right: var(--r6);
}

.my-1 {
  margin-top: 0.375rem;
  margin-top: var(--r1_5);
  margin-bottom: 0.375rem;
  margin-bottom: var(--r1_5);
}
.my-2 {
  margin-top: 0.5rem;
  margin-top: var(--r2);
  margin-bottom: 0.5rem;
  margin-bottom: var(--r2);
}
.my-3 {
  margin-top: 0.75rem;
  margin-top: var(--r3);
  margin-bottom: 0.75rem;
  margin-bottom: var(--r3);
}
.my-4 {
  margin-top: 1rem;
  margin-top: var(--r4);
  margin-bottom: 1rem;
  margin-bottom: var(--r4);
}
.my-5 {
  margin-top: 1.25rem;
  margin-top: var(--r5);
  margin-bottom: 1.25rem;
  margin-bottom: var(--r5);
}
.my-6 {
  margin-top: 1.5rem;
  margin-top: var(--r6);
  margin-bottom: 1.5rem;
  margin-bottom: var(--r6);
}

.ml-1 {
  margin-left: 0.375rem;
  margin-left: var(--r1_5);
}
.ml-2 {
  margin-left: 0.5rem;
  margin-left: var(--r2);
}
.ml-3 {
  margin-left: 0.75rem;
  margin-left: var(--r3);
}
.ml-4 {
  margin-left: 1rem;
  margin-left: var(--r4);
}
.ml-5 {
  margin-left: 1.25rem;
  margin-left: var(--r5);
}
.ml-6 {
  margin-left: 1.5rem;
  margin-left: var(--r6);
}

.mr-1 {
  margin-right: 0.375rem;
  margin-right: var(--r1_5);
}
.mr-2 {
  margin-right: 0.5rem;
  margin-right: var(--r2);
}
.mr-3 {
  margin-right: 0.75rem;
  margin-right: var(--r3);
}
.mr-4 {
  margin-right: 1rem;
  margin-right: var(--r4);
}
.mr-5 {
  margin-right: 1.25rem;
  margin-right: var(--r5);
}
.mr-6 {
  margin-right: 1.5rem;
  margin-right: var(--r6);
}

.mt-1 {
  margin-top: 0.375rem;
  margin-top: var(--r1_5);
}
.mt-2 {
  margin-top: 0.5rem;
  margin-top: var(--r2);
}
.mt-3 {
  margin-top: 0.75rem;
  margin-top: var(--r3);
}
.mt-4 {
  margin-top: 1rem;
  margin-top: var(--r4);
}
.mt-5 {
  margin-top: 1.25rem;
  margin-top: var(--r5);
}
.mt-6 {
  margin-top: 1.5rem;
  margin-top: var(--r6);
}

.mb-1 {
  margin-bottom: 0.375rem;
  margin-bottom: var(--r1_5);
}

.mb-2 {
  margin-bottom: 0.5rem;
  margin-bottom: var(--r2);
}
.mb-3 {
  margin-bottom: 0.75rem;
  margin-bottom: var(--r3);
}
.mb-4 {
  margin-bottom: 1rem;
  margin-bottom: var(--r4);
}
.mb-5 {
  margin-bottom: 1.25rem;
  margin-bottom: var(--r5);
}
.mb-6 {
  margin-bottom: 1.5rem;
  margin-bottom: var(--r6);
}

/* display */
.block {
  display: block;
}
.flex {
  display: flex;
}
.inline-block {
  display: inline-block;
}

/* flex */

.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.wrap {
  flex-wrap: wrap;
}
.nowrap {
  flex-wrap: nowrap;
}

.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}

.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.items-stretch {
  align-items: stretch;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.shrink {
  flex-shrink: 1;
}
.shrink-0 {
  flex-shrink: 0;
}
.grow {
  flex-grow: 1;
}
.grow-0 {
  flex-grow: 0;
}
.drop-shadow-sm {
  -webkit-filter: drop-shadow(0 1px 1px hsla(0, 0%, 0%, 0.1));
          filter: drop-shadow(0 1px 1px hsla(0, 0%, 0%, 0.1));
}
.drop-shadow {
  -webkit-filter: drop-shadow(0 1px 2px hsla(0, 0%, 0%, 0.2))
    drop-shadow(0 1px 1px hsla(0, 0%, 0%, 0.06));
          filter: drop-shadow(0 1px 2px hsla(0, 0%, 0%, 0.2))
    drop-shadow(0 1px 1px hsla(0, 0%, 0%, 0.06));
}
.drop-shadow-md {
  -webkit-filter: drop-shadow(0 4px 3px hsla(0, 0%, 0%, 0.15))
    drop-shadow(0 2px 2px hsla(0, 0%, 0%, 0.1));
          filter: drop-shadow(0 4px 3px hsla(0, 0%, 0%, 0.15))
    drop-shadow(0 2px 2px hsla(0, 0%, 0%, 0.1));
}
.drop-shadow-lg {
  -webkit-filter: drop-shadow(0 10px 8px hsla(0, 0%, 0%, 0.1))
    drop-shadow(0 4px 3px hsla(0, 0%, 0%, 0.2));
          filter: drop-shadow(0 10px 8px hsla(0, 0%, 0%, 0.1))
    drop-shadow(0 4px 3px hsla(0, 0%, 0%, 0.2));
}
.drop-shadow-xl {
  -webkit-filter: drop-shadow(0 20px 13px hsla(0, 0%, 0%, 0.1))
    drop-shadow(0 8px 5px hsla(0, 0%, 0%, 0.2));
          filter: drop-shadow(0 20px 13px hsla(0, 0%, 0%, 0.1))
    drop-shadow(0 8px 5px hsla(0, 0%, 0%, 0.2));
}
.drop-shadow-2xl {
  -webkit-filter: drop-shadow(0 25px 25px hsla(0, 0%, 0%, 0.3));
          filter: drop-shadow(0 25px 25px hsla(0, 0%, 0%, 0.3));
}
.drop-shadow-none {
  -webkit-filter: drop-shadow(0 0 #0000);
          filter: drop-shadow(0 0 #0000);
}
.drop-shadow-ui {
  -webkit-filter: drop-shadow(0 0 8px hsla(0, 0%, 0%, 0.4))
    drop-shadow(0 0 2px hsla(0, 0%, 0%, 0.7));
          filter: drop-shadow(0 0 8px hsla(0, 0%, 0%, 0.4))
    drop-shadow(0 0 2px hsla(0, 0%, 0%, 0.7));
}

/* color */
.textGreyA {
  color: hsl(240, 0%, 95%);
  color: var(--greyA);
}
.textGreyB {
  color: hsl(240, 2%, 78%);
  color: var(--greyB);
}
.textGreyC {
  color: hsl(240, 3%, 62%);
  color: var(--greyC);
}
.textGreyD {
  color: hsl(240, 4%, 45%);
  color: var(--greyD);
}
.textGreyE {
  color: hsl(240, 5%, 35%);
  color: var(--greyE);
}
.textGreyF {
  color: hsl(240, 5%, 23%);
  color: var(--greyF);
}
.textGreyG {
  color: hsl(240, 6%, 20%);
  color: var(--greyG);
}
.textGreyH {
  color: hsl(240, 6%, 16%);
  color: var(--greyH);
}
.textGreyI {
  color: hsl(240, 6%, 14%);
  color: var(--greyI);
}

.textSuccess {
  color: #a9b1a9;
  color: var(--success);
}
.textInfo {
  color: #838ec2;
  color: var(--info);
}
.textWarning {
  color: #e0ac5d;
  color: var(--warning);
}
.textError {
  color: #b67584;
  color: var(--error);
}

/* background colors */
.bgGreyA {
  background-color: hsl(240, 0%, 95%);
  background-color: var(--greyA);
}
.bgGreyB {
  background-color: hsl(240, 2%, 78%);
  background-color: var(--greyB);
}
.bgGreyC {
  background-color: hsl(240, 3%, 62%);
  background-color: var(--greyC);
}
.bgGreyD {
  background-color: hsl(240, 4%, 45%);
  background-color: var(--greyD);
}
.bgGreyE {
  background-color: hsl(240, 5%, 35%);
  background-color: var(--greyE);
}
.bgGreyF {
  background-color: hsl(240, 5%, 23%);
  background-color: var(--greyF);
}
.bgGreyG {
  background-color: hsl(240, 6%, 20%);
  background-color: var(--greyG);
}
.bgGreyH {
  background-color: hsl(240, 6%, 16%);
  background-color: var(--greyH);
}
.bgGreyI {
  background-color: hsl(240, 6%, 14%);
  background-color: var(--greyI);
}
.bgNone {
  background-color: transparent !important;
}
.bgTransparent {
  background-color: hsla(240, 5%, 11%, 0.745);
  background-color: var(--transparentBg);
}

/* border colors */
.borderGreyA {
  border-style: solid;
  border-color: hsl(240, 0%, 95%);
  border-color: var(--greyA);
  border-width: 2px;
}
.borderGreyB {
  border-style: solid;
  border-color: hsl(240, 2%, 78%);
  border-color: var(--greyB);
  border-width: 2px;
}
.borderGreyC {
  border-style: solid;
  border-color: hsl(240, 3%, 62%);
  border-color: var(--greyC);
  border-width: 2px;
}
.borderGreyD {
  border-style: solid;
  border-color: hsl(240, 4%, 45%);
  border-color: var(--greyD);
  border-width: 2px;
}
.borderGreyE {
  border-style: solid;
  border-color: hsl(240, 5%, 35%);
  border-color: var(--greyE);
  border-width: 2px;
}
.borderGreyF {
  border-style: solid;
  border-color: hsl(240, 5%, 23%);
  border-color: var(--greyF);
  border-width: 2px;
}
.borderGreyG {
  border-style: solid;
  border-color: hsl(240, 6%, 20%);
  border-color: var(--greyG);
  border-width: 2px;
}
.borderGreyH {
  border-style: solid;
  border-color: hsl(240, 6%, 16%);
  border-color: var(--greyH);
  border-width: 2px;
}
.borderGreyI {
  border-style: solid;
  border-color: hsl(240, 6%, 14%);
  border-color: var(--greyI);
  border-width: 2px;
}
.borderNone {
  border: none !important;
}

/* Labels 
DON'T rename the following unless you rename the css strings as well.
We use the selector string for these rules to reference them programmatically
They are selected in Bootstrap.tsx and used in Perception3JS.tsx

!!!!IMPORTANT Keep the rules unique or at build time they will be concatenated into one rule
and we will fail programmatically getting a reference to them. !!!!!!
*/

/* Tracked Objects Labels */
.gui-label[data-classification='Prospect'] {
  color: rgb(40, 40, 40);
}
.gui-label[data-classification='Bicycle'] {
  color: rgb(50, 50, 50);
}
.gui-label[data-classification='Cluster'] {
  color: rgb(55, 55, 55);
}
.gui-label[data-classification='Unknown'] {
  color: rgb(60, 60, 60);
}
.gui-label[data-classification='Person'] {
  color: rgb(80, 80, 80);
}
.gui-label[data-classification='Vehicle'] {
  color: rgb(90, 90, 90);
}
.gui-label[data-classification='LargeVehicle'] {
  color: rgb(100, 100, 100);
}

.gui-label[data-classification='Inclusion'] {
  color: rgb(110, 110, 110);
}

.gui-label[data-classification='Exclusion'] {
  color: rgb(120, 120, 120);
}

.gui-label[data-classification='Event'] {
  color: rgb(130, 130, 130);
}

.gui-label .dirty {
  color: #e0ac5d;
  color: var(--warning);
  font-weight: 900;
  font-size: 1.25rem;
  font-size: var(--r5);
}
.dirty::before {
  content: '!';
}

/* Range Ring Labels */
.polar-grid {
  color: hsl(240, 4%, 45%);
  color: var(--greyD);
  background-color: hsl(240, 6%, 16%);
  background-color: var(--greyH);
  border-radius: 3px;
  border-radius: var(--border-radius);
  padding: 0.25rem;
  font-weight: 600;

  box-shadow: 0px 0px 2px hsl(240, 6%, 14%), 0px 0px 4px hsl(240, 6%, 14%);

  box-shadow: 0px 0px 2px var(--greyI), 0px 0px 4px var(--greyI);
}
/* End of Labels */

.gui-label {
  font-size: 0.75rem;
  font-size: var(--r3);
  line-height: 1.75;
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  white-space: nowrap;
}
#details {
  position: absolute;
  background-color: hsla(240, 5%, 11%, 0.745);
  background-color: var(--transparentBg);
  padding: 0.25rem 0.5rem;
  bottom: 0;
}
.gui-header {
  font-size: 0.875rem;
  font-size: var(--r3_5);
}
.gui-row {
  font-family: 'Spezia', 'Roboto Mono', Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-family: var(--mono);
  display: flex;
  justify-content: space-between;
}
.gui-key {
  display: flex;
  color: hsl(240, 4%, 45%);
  color: var(--greyD);
}
.gui-value {
  display: flex;
  color: hsl(240, 2%, 78%);
  color: var(--greyB);
}

.frameOfReference {
  position: relative;
}
.frameOfReference #offset {
  -webkit-transform: translate(0, -50px) scale(2);
          transform: translate(0, -50px) scale(2);
}

.gui-label[data-classification='Event'] #occupations {
  transition: opacity ease-in-out 200ms;
  opacity: 0;
}
.gui-label[data-classification='Event'] #occupations.show {
  opacity: 1;
}

.gui-label[data-classification='Inclusion'] div,
.gui-label[data-classification='Exclusion'] div,
.gui-label[data-classification='Event'] div {
  bottom: 24px;
  position: relative;
}

/* Id matches SELECTION_DIV_ID */
#selectionDiv {
  border: 1px solid #ff9800;
  border: 1px solid var(--highlighted);
  position: fixed;
  pointer-events: none;
}

/* style */
body {
  background-color: hsl(240, 6%, 16%);
  background-color: var(--greyH);
  color: hsl(240, 3%, 62%);
  color: var(--greyC);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: hsl(240, 2%, 78%);
  color: var(--greyB);
}

label {
  color: hsl(240, 3%, 62%);
  color: var(--greyC);
  display: inline-flex;
  align-items: center;
  font-size: 0.75rem;
  font-size: var(--r3);
  font-weight: 300;
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  border-bottom: 1px dotted hsl(240, 3%, 62%);
  border-bottom: 1px dotted var(--greyC);
}

input,
textarea {
  line-height: 1.75;
  font-size: 1rem;
  font-size: var(--r4);
  color: hsl(240, 2%, 78%);
  color: var(--greyB);
  background-color: hsl(240, 6%, 14%);
  background-color: var(--greyI);
}
input:disabled,
textarea:disabled {
  background-color: hsl(240, 6%, 16%);
  background-color: var(--greyH);
  color: hsl(240, 4%, 45%);
  color: var(--greyD);
  cursor: not-allowed;
}

input:disabled,
textarea:disabled {
  background-color: hsl(240, 6%, 16%);
  background-color: var(--greyH);
  color: hsl(240, 4%, 45%);
  color: var(--greyD);
  cursor: not-allowed;
}

input[type='text'],
input[type='number'],
textarea {
  border: none;
  text-align: right;
  padding: 0 0.5rem;
  padding: 0 var(--r2);
}

/* Hide number input arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -webkit-appearance: textfield;
          appearance: textfield;
}

input[type='checkbox'] {
  -webkit-appearance: none;
          appearance: none;
  width: 1rem;
  width: var(--r4);
  height: 1rem;
  height: var(--r4);
  background-color: hsl(240, 6%, 16%);
  background-color: var(--greyH);
  margin: 0.125rem 0.375rem;
  margin: 0.125rem var(--r1_5);
}

input[type='checkbox']:checked {
  background-color: hsl(240, 2%, 78%);
  background-color: var(--greyB);
  border: solid hsl(240, 6%, 14%) 0.35rem;
  border: solid var(--greyI) 0.35rem;
}

input[type='radio'] {
  -webkit-appearance: none;
          appearance: none;
  background-color: hsl(240, 6%, 14%);
  background-color: var(--greyI);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 1.25rem;
  width: var(--r5);
  height: 1.25rem;
  height: var(--r5);
  cursor: pointer;
  border-radius: 50%;
}
input[type='radio']:checked {
  background-color: hsl(240, 2%, 78%);
  background-color: var(--greyB);
  border: solid hsl(240, 6%, 14%) 0.375rem;
  border: solid var(--greyI) var(--r1_5);
}

/* New select formatting starts (see /src/app/components/Select.tsx) */
select.new-select {
  -webkit-appearance: none;
          appearance: none;
  background-color: transparent;
  line-height: inherit;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  outline: none;
  border: none;
  width: 100%;
  padding: 0.5rem 1.25rem 0.5rem 0.5rem;
  padding: var(--r2) var(--r5) var(--r2) var(--r2);
}
select.new-select::-ms-expand {
  display: none;
}
.select {
  position: relative;
  color: hsl(240, 3%, 62%);
  color: var(--greyC);
  background: hsl(240, 6%, 14%);
  background: var(--greyI);
  font-size: 0.875rem;
  font-size: var(--r3_5);
  border: solid 1px hsl(240, 6%, 20%);
  border: solid 1px var(--greyG);
  border-radius: 3px;
  border-radius: var(--border-radius);
  cursor: pointer;
  min-width: 6rem;
  min-width: var(--r24);
  padding: 0;
}
.select-arrow {
  position: absolute;
  right: 0.15rem;
  top: 0.5rem;
}
select.new-select:focus + .focus {
  position: absolute;
  top: -0.5px;
  left: -0.5px;
  right: -0.5px;
  bottom: -0.5px;
  border: solid 1px hsl(240, 3%, 62%);
  border: solid 1px var(--greyC);
  border-radius: inherit;
}

select.new-select option {
  background: hsl(240, 5%, 23%);
  background: var(--greyF);
  text-align: right;
  cursor: pointer;
}
/* New select formatting ends */

/* Legacy select formatting starts */
select {
  color: hsl(240, 3%, 62%);
  color: var(--greyC);
  background-color: hsl(240, 6%, 14%);
  background-color: var(--greyI);
  padding: 0.5rem;
  padding: var(--r2);
  font-size: 0.875rem;
  font-size: var(--r3_5);
  border-color: hsl(240, 6%, 16%);
  border-color: var(--greyH);
  cursor: pointer;
  min-width: 7rem;
}

select option {
  background-color: hsl(240, 5%, 23%);
  background-color: var(--greyF);
  text-align: right;
}

option:hover {
  background-color: hsl(240, 5%, 23%);
  background-color: var(--greyF);
  padding: 1rem;
  padding: var(--r4);
}
/* Legacy select formatting ends */

br {
  display: block;
  margin: 0.5rem 0 0.5rem 0;
  margin: var(--r2) 0 var(--r2) 0;
  content: ' ';
}
/* 'bgGreyG text-sm px-1 py-2 my-2 borderGreyE grow' */
button {
  font-family: 'Spezia', 'Roboto', 'Arial', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  font-family: var(--sans);
  color: hsl(240, 2%, 78%);
  color: var(--greyB);
  background-color: hsl(240, 6%, 14%);
  background-color: var(--greyI);
  padding: 0.75rem 0.75rem;
  padding: var(--r3) var(--r3);
  border: none;
  border-radius: 3px;
  border-radius: var(--border-radius);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  font-size: 0.875rem;
  font-size: var(--r3_5);
  max-width: 20rem;
  max-width: var(--r80);
}
button:disabled {
  color: hsl(240, 5%, 35%);
  color: var(--greyE);
  cursor: not-allowed;
  background-color: transparent;
  border: solid 1px hsl(240, 6%, 20%);
  border: solid 1px var(--greyG);
  padding: calc(0.75rem - 1px) calc(0.75rem - 1px);
  padding: calc(var(--r3) - 1px) calc(var(--r3) - 1px);
}
button:hover:enabled {
  color: hsl(240, 0%, 95%);
  color: var(--greyA);
  background-color: hsl(240, 6%, 20%);
  background-color: var(--greyG);
  cursor: pointer;
}
button:active:enabled {
  color: hsl(240, 2%, 78%);
  color: var(--greyB);
  background-color: hsl(240, 6%, 16%);
  background-color: var(--greyH);
}
button.action:focus:enabled {
  outline: none;
  border: solid 1px hsl(240, 4%, 45%);
  border: solid 1px var(--greyD);
  padding: calc(0.75rem - 1px) calc(0.75rem - 1px);
  padding: calc(var(--r3) - 1px) calc(var(--r3) - 1px);
}

/* Warning Banner */
.warningBanner {
  position: relative;
  background-color: #bf302e;
  background-color: var(--critical);
  width: 100%;
  height: 2.5rem;
  height: var(--r10);
  border-radius: 0.25rem;
  border-radius: var(--r1);
  color: hsl(240, 0%, 95%);
  color: var(--greyA);
  font-size: 0.9rem;
  line-height: 2rem;
  padding: 0.25rem;
  padding: var(--r1);
  padding-left: 0.75rem;
  padding-left: var(--r3);
  cursor: pointer;
  transition: box-shadow 0.1s, background-color 0.1s, -webkit-transform 0.1s;
  transition: transform 0.1s, box-shadow 0.1s, background-color 0.1s;
  transition: transform 0.1s, box-shadow 0.1s, background-color 0.1s, -webkit-transform 0.1s;
}
.warningBanner:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.6);
  background-color: #db4644;
  background-color: var(--criticalHover);
}
button.close {
  background-color: transparent;
  border: none;
  border-radius: 0.25rem;
  border-radius: var(--r1);
  width: 1rem;
  width: var(--r4);
  height: 1rem;
  height: var(--r4);
  font-weight: 500;
  font-size: 1rem;
  font-size: var(--r4);
  line-height: 1rem;
  line-height: var(--r4);
  color: hsl(240, 0%, 95%);
  color: var(--greyA);
}
button.close:hover {
  background-color: rgba(115, 10, 10, 0.2);
}

button svg {
  fill: hsl(240, 4%, 45%);
  fill: var(--greyD);
}
button svg.active {
  fill: hsl(240, 2%, 78%);
  fill: var(--greyB);
}
button:disabled svg {
  fill: hsl(240, 5%, 23%);
  fill: var(--greyF);
}
button:enabled:hover svg {
  fill: hsl(240, 0%, 95%);
  fill: var(--greyA);
}

button svg.VisibilityIcon {
  fill: hsl(240, 5%, 35%);
  fill: var(--greyE);
}
button svg.VisibilityIcon.active {
  fill: hsl(240, 3%, 62%);
  fill: var(--greyC);
}
button:disabled svg.VisibilityIcon {
  fill: hsl(240, 5%, 23%);
  fill: var(--greyF);
}
button:enabled:hover svg.VisibilityIcon {
  fill: hsl(240, 2%, 78%);
  fill: var(--greyB);
}

button:disabled svg.UpDownIcon {
  fill: hsl(240, 5%, 35%);
  fill: var(--greyE);
}

button.action {
  margin: 0.375rem;
  margin: var(--r1_5);
}

/* Abbreviation popups - used for click to copy */
abbr[title] {
  transition: -webkit-filter 0s ease-in-out;
  transition: filter 0s ease-in-out;
  transition: filter 0s ease-in-out, -webkit-filter 0s ease-in-out;
}

abbr[title]:not(:hover),
abbr[title]:not(.enabled) {
  border-bottom: none !important;
  cursor: inherit !important;
  text-decoration: none !important;
}

abbr[title].enabled:active {
  -webkit-filter: brightness(1.5);
          filter: brightness(1.5);
}
abbr[title].enabled:not(:active) {
  transition: -webkit-filter 0.25s ease-in-out;
  transition: filter 0.25s ease-in-out;
  transition: filter 0.25s ease-in-out, -webkit-filter 0.25s ease-in-out;
}

/* under the sun */
.Light svg.Logo {
  fill: black;
}

.Light .text-xxs {
  font-size: 1rem;
  font-size: var(--r4);
}
.Light .text-xs {
  font-size: 1rem;
  font-size: var(--r4);
}
.Light .text-sm {
  font-size: 1rem;
  font-size: var(--r4);
}
.Light .text-base {
  font-size: 1rem;
  font-size: var(--r4);
}
.Light .text-base {
  font-size: 1.25rem;
  font-size: var(--r5);
}
.Light .text-lg {
  font-size: 1.5rem;
  font-size: var(--r6);
}
.Light .text-xl {
  font-size: 1.75rem;
  font-size: var(--r7);
}
.Light .text-2xl {
  font-size: 2rem;
  font-size: var(--r8);
}
.Light .text-3xl {
  font-size: 2.5rem;
  font-size: var(--r10);
}

.Light {
  --greyA: var(--secIndigo);
  --greyB: hsl(0 0% 0%);
  --greyC: hsl(0, 0%, 10%);
  --greyD: hsl(0 0% 25%);
  --greyE: hsl(0 0% 30%);
  --greyF: hsl(0 0% 65%);
  --greyG: hsl(0 0% 70%);
  --greyH: hsl(0 0% 88%);
  --greyI: hsl(0 0% 91%);
  --greyJ: hsl(0 0% 95%);

  font-weight: 500;

  --info: #324ac4;
  --success: #afda39;
  --warning: #f89f2b;
  --error: #d62a52;

  --critical: #db4644;
  --criticalHover: #e85351;

  --transparentBg: hsla(0, 0%, 100%, 0.365);
}

.Light .drop-shadow-ui {
  -webkit-filter: drop-shadow(0 0 3px hsla(0, 0%, 100%, 0.6))
    drop-shadow(0 0 1px hsla(0, 0%, 100%, 0.8));
          filter: drop-shadow(0 0 3px hsla(0, 0%, 100%, 0.6))
    drop-shadow(0 0 1px hsla(0, 0%, 100%, 0.8));
}

