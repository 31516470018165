/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

.button {
  width: 24px;
  height: 24px;
  border: 3px solid var(--greyB);
  border-radius: 50%;
  cursor: pointer;

  /* Transform to preserve centering */
  transform-origin: center;
  /* Width and height match that for play button (18px) : 24px * 0.83 = 20px*/
  transform: scale(0.833);
  transition: filter 0.2s ease;
}

.disabled .button {
  cursor: not-allowed;
  filter: brightness(0.3);
  will-change: filter;
}

.inner {
  background-color: var(--error);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  will-change: transform;

  transform-origin: center;
  transition: transform 0.2s ease;
  transform: scale(0.85);
}

.button.active .inner {
  transform: scale(0.5);
  border-radius: 12%;
}

/* Recording blinker */
.recording-blinker {
  animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}
@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Marquee recording name */
.marquee {
  width: 8rem; /* Width of marquee "window" must match the width of p elements*/
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}

.marquee div {
  display: table-row;
  white-space: nowrap;
  padding-left: 100%;
  animation: marquee 10s linear infinite; /* Time must be adjusted based on total width of scrolled elements*/
}

.marquee div span {
  width: 8rem; /* Width of p elements must match the width of marquee "window"*/
  padding-left: 8rem; /* Padding determines space between scrolled elements */
  display: table-cell;
}

/* Make it move */
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
