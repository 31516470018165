/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

:root {
  --table-min-width: 1150px;
}

.diagnostics table {
  border: none;
  font-family: arial, sans-serif;
  border-collapse: collapse;
}

.diagnostics-tables {
  position: absolute;
  overflow-y: auto;
}

.diagnostics .table-type-section {
  display: flex;
  flex-direction: row;
}
.diagnostics .table-type-section:not(:first-child) {
  margin-top: var(--r6);
}

.diagnostics td,
.diagnostics th {
  border-top: none;
  text-align: center;
  padding: var(--r2);
}

.diagnostics tr {
  border: none;
  cursor: pointer;
  justify-content: flex-start;
}

.diagnostics td:not(.expanded-row-content),
.diagnostics tr {
  display: grid;
  grid-template-columns: 0.15fr 0.5fr 1fr 0.5fr 0.5fr 0.5fr 0.25fr 0;
}

.diagnostics tr:not(.table-header) {
  color: var(--greyC);
  background-color: var(--greyI);
}

.diagnostics .lastOccured,
.diagnostics .firstOccured,
.diagnostics .alertCode {
  color: var(--greyD);
}

.diagnostics tr:not(.table-header):hover {
  color: var(--hover);
  background-color: var(--greyH);
}
.diagnostics tr.active {
  color: var(--hover);
  background-color: var(--greyH);
}
.diagnostics tr.active:hover {
  filter: brightness(1.1);
}
.diagnostics tr:hover td button {
  filter: brightness(1.1);
}

.diagnostics .expanded-row-content {
  border-top: none;
  padding: 0 8px;
  /* Add a max-height we'll never reach to emulate transitioning to height: auto */
  max-height: 100vh;
  overflow: hidden;
  opacity: 1;
  display: grid;
  grid-column: 1/-1;
  justify-content: flex-start;
  transition: max-height ease-in-out, opacity ease-in-out;
  transition-duration: 0.75s;
}

.diagnostics .hide-row {
  max-height: 0px;
  opacity: 0;
  transition-duration: 0.4s;
}

.diagnostics .warning {
  color: var(--warning);
}
.diagnostics .error {
  color: var(--error);
}
.diagnostics .fatal {
  color: var(--fatal);
}

#diagnostics-layout {
  background: var(--greyI);
  width: 100vw;
  height: 90vh;
  display: grid;
  grid-template-areas: 'content pane-right';
  grid-template-columns: 1fr var(--r80);
  overflow: hidden;
}

#diagnostics-pane {
  grid-area: pane-right;
  overflow-y: hidden;
  background-color: var(--greyH);
  width: var(--r80);
}
