/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

@font-face {
  font-family: 'Spezia';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/Spezia-Thin.otf') format('opentype');
}

@font-face {
  font-family: 'Spezia';
  font-style: italic;
  font-weight: 100;
  src: url('./fonts/Spezia-ThinItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Spezia';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/Spezia-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Spezia';
  font-style: italic;
  font-weight: 200;
  src: url('./fonts/Spezia-LightItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Spezia';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/Spezia-Book.otf') format('opentype');
}

@font-face {
  font-family: 'Spezia';
  font-style: italic;
  font-weight: 300;
  src: url('./fonts/Spezia-BookItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Spezia';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Spezia-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Spezia';
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/Spezia-RegularItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Spezia';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/Spezia-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Spezia';
  font-style: italic;
  font-weight: 500;
  src: url('./fonts/Spezia-MediumItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Spezia';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/Spezia-SemiBold.otf') format('opentype');
}

@font-face {
  font-family: 'Spezia';
  font-style: italic;
  font-weight: 600;
  src: url('./fonts/Spezia-SemiBoldItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Spezia';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/Spezia-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Spezia';
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/Spezia-BoldItalic.otf') format('opentype');
}

/* fallback */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: var(--sans);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: var(--mono);
}

/* Basic reset  */
html {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

svg.Logo {
  fill: white;
}

:root {
  --sans: 'Spezia', 'Roboto', 'Arial', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  --mono: 'Spezia', 'Roboto Mono', Menlo, Monaco, Consolas, 'Courier New',
    monospace;

  /* color scheme */
  --greyA: hsl(240, 0%, 95%);
  --greyB: hsl(240, 2%, 78%);
  --greyC: hsl(240, 3%, 62%);
  --greyD: hsl(240, 4%, 45%);
  --greyE: hsl(240, 5%, 35%);
  --greyF: hsl(240, 5%, 23%);
  --greyG: hsl(240, 6%, 20%);
  --greyH: hsl(240, 6%, 16%);
  --greyI: hsl(240, 6%, 14%);
  --greyJ: hsl(240, 7%, 12%);

  --primMilkyWay: #ffffff;
  --primCrater: #e3e3e3;
  --primGravity: #c6c6c6;
  --primGalaxy: #3c3c3c;
  --primBlackHole: #000000;
  --secIndigo: #031f50;
  --secBlue: #35459c;
  --secMagenta: #b43996;

  --active: #70b339;
  --unknown: #ccc91f;
  --inactive: #c21f2f;

  --info: #838ec2;
  --success: #a9b1a9;
  --warning: #e0ac5d;
  --error: #b67584;
  --fatal: #69313e;
  /* previous colours: */
  /* more vibrant: #d4211e; */
  --critical: #bf302e;
  --criticalHover: #db4644;

  --ideal: #607d8b;
  --highlighted: #ff9800;
  --selected: #ff5722;

  --transparentBg: hsla(240, 5%, 11%, 0.745);

  --r1: 0.25rem;
  --r1_5: 0.375rem;
  --r2: 0.5rem;
  --r2_5: 0.625rem;
  --r3: 0.75rem;
  --r3_5: 0.875rem;
  --r4: 1rem;
  --r5: 1.25rem;
  --r6: 1.5rem;
  --r7: 1.75rem;
  --r8: 2rem;
  --r9: 2.25rem;
  --r10: 2.5rem;
  --r16: 4rem;
  --r24: 6rem;
  --r32: 8rem;
  --r64: 16rem;
  --r80: 20rem;

  --border-radius: 3px;

  --header-h: 3rem;
  --sub-header-h: 4rem;
  --footer-h: 1.5rem;
  --scrollbar-w: 15px;
}

::selection {
  color: var(--greyH);
  background: var(--greyB);
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: var(--scrollbar-w);
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: var(--greyF);
  border-radius: 30px;
  border: 5px solid transparent;
  background-clip: content-box;
  outline-offset: -20px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--greyE);
}
::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

:focus {
  outline: none;
}

/* class utilities */
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.cursor-pointer {
  cursor: pointer;
}
.overflow-y {
  overflow-y: overlay;
}
.overflow-hidden {
  overflow: hidden;
}
.no-select {
  user-select: none;
}
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}

/* dfg */
.text-xxs {
  font-size: var(--r2_5);
}
.text-xs {
  font-size: var(--r3);
}
.text-sm {
  font-size: var(--r3_5);
}
.text-base {
  font-size: var(--r4);
}
.text-lg {
  font-size: var(--r5);
}
.text-xl {
  font-size: var(--r6);
}
.text-2xl {
  font-size: var(--r7);
}
.text-3xl {
  font-size: var(--r9);
}

/* padding */

.p-0 {
  padding: 0;
}
.p-0_5 {
  padding: 4px;
}
.p-1 {
  padding: var(--r1_5);
}
.p-2 {
  padding: var(--r2);
}
.p-3 {
  padding: var(--r3);
}
.p-4 {
  padding: var(--r4);
}
.px-1 {
  padding-left: var(--r1_5);
  padding-right: var(--r1_5);
}
.px-2 {
  padding-left: var(--r2);
  padding-right: var(--r2);
}
.px-3 {
  padding-left: var(--r3);
  padding-right: var(--r3);
}
.px-4 {
  padding-left: var(--r4);
  padding-right: var(--r4);
}
.px-4 {
  padding-left: var(--r4);
  padding-right: var(--r4);
}
.px-5 {
  padding-left: var(--r5);
  padding-right: var(--r5);
}
.px-6 {
  padding-left: var(--r6);
  padding-right: var(--r6);
}
.px-7 {
  padding-left: var(--r7);
  padding-right: var(--r7);
}
.py-1 {
  padding-top: var(--r1_5);
  padding-bottom: var(--r1_5);
}
.py-2 {
  padding-top: var(--r2);
  padding-bottom: var(--r2);
}
.py-3 {
  padding-top: var(--r3);
  padding-bottom: var(--r3);
}
.py-4 {
  padding-top: var(--r4);
  padding-bottom: var(--r4);
}
.py-5 {
  padding-top: var(--r5);
  padding-bottom: var(--r5);
}
.py-6 {
  padding-top: var(--r6);
  padding-bottom: var(--r6);
}
.py-7 {
  padding-top: var(--r7);
  padding-bottom: var(--r7);
}

/* margin */
.m-1 {
  margin: var(--r1_5);
}
.m-2 {
  margin: var(--r2);
}
.m-3 {
  margin: var(--r3);
}
.m-4 {
  margin: var(--r4);
}
.m-5 {
  margin: var(--r5);
}
.m-6 {
  margin: var(--r6);
}

.mx-1 {
  margin-left: var(--r1_5);
  margin-right: var(--r1_5);
}
.mx-2 {
  margin-left: var(--r2);
  margin-right: var(--r2);
}
.mx-3 {
  margin-left: var(--r3);
  margin-right: var(--r3);
}
.mx-4 {
  margin-left: var(--r4);
  margin-right: var(--r4);
}
.mx-5 {
  margin-left: var(--r5);
  margin-right: var(--r5);
}
.mx-6 {
  margin-left: var(--r6);
  margin-right: var(--r6);
}

.my-1 {
  margin-top: var(--r1_5);
  margin-bottom: var(--r1_5);
}
.my-2 {
  margin-top: var(--r2);
  margin-bottom: var(--r2);
}
.my-3 {
  margin-top: var(--r3);
  margin-bottom: var(--r3);
}
.my-4 {
  margin-top: var(--r4);
  margin-bottom: var(--r4);
}
.my-5 {
  margin-top: var(--r5);
  margin-bottom: var(--r5);
}
.my-6 {
  margin-top: var(--r6);
  margin-bottom: var(--r6);
}

.ml-1 {
  margin-left: var(--r1_5);
}
.ml-2 {
  margin-left: var(--r2);
}
.ml-3 {
  margin-left: var(--r3);
}
.ml-4 {
  margin-left: var(--r4);
}
.ml-5 {
  margin-left: var(--r5);
}
.ml-6 {
  margin-left: var(--r6);
}

.mr-1 {
  margin-right: var(--r1_5);
}
.mr-2 {
  margin-right: var(--r2);
}
.mr-3 {
  margin-right: var(--r3);
}
.mr-4 {
  margin-right: var(--r4);
}
.mr-5 {
  margin-right: var(--r5);
}
.mr-6 {
  margin-right: var(--r6);
}

.mt-1 {
  margin-top: var(--r1_5);
}
.mt-2 {
  margin-top: var(--r2);
}
.mt-3 {
  margin-top: var(--r3);
}
.mt-4 {
  margin-top: var(--r4);
}
.mt-5 {
  margin-top: var(--r5);
}
.mt-6 {
  margin-top: var(--r6);
}

.mb-1 {
  margin-bottom: var(--r1_5);
}

.mb-2 {
  margin-bottom: var(--r2);
}
.mb-3 {
  margin-bottom: var(--r3);
}
.mb-4 {
  margin-bottom: var(--r4);
}
.mb-5 {
  margin-bottom: var(--r5);
}
.mb-6 {
  margin-bottom: var(--r6);
}

/* display */
.block {
  display: block;
}
.flex {
  display: flex;
}
.inline-block {
  display: inline-block;
}

/* flex */

.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.wrap {
  flex-wrap: wrap;
}
.nowrap {
  flex-wrap: nowrap;
}

.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}

.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.items-stretch {
  align-items: stretch;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.shrink {
  flex-shrink: 1;
}
.shrink-0 {
  flex-shrink: 0;
}
.grow {
  flex-grow: 1;
}
.grow-0 {
  flex-grow: 0;
}
.drop-shadow-sm {
  filter: drop-shadow(0 1px 1px hsla(0, 0%, 0%, 0.1));
}
.drop-shadow {
  filter: drop-shadow(0 1px 2px hsla(0, 0%, 0%, 0.2))
    drop-shadow(0 1px 1px hsla(0, 0%, 0%, 0.06));
}
.drop-shadow-md {
  filter: drop-shadow(0 4px 3px hsla(0, 0%, 0%, 0.15))
    drop-shadow(0 2px 2px hsla(0, 0%, 0%, 0.1));
}
.drop-shadow-lg {
  filter: drop-shadow(0 10px 8px hsla(0, 0%, 0%, 0.1))
    drop-shadow(0 4px 3px hsla(0, 0%, 0%, 0.2));
}
.drop-shadow-xl {
  filter: drop-shadow(0 20px 13px hsla(0, 0%, 0%, 0.1))
    drop-shadow(0 8px 5px hsla(0, 0%, 0%, 0.2));
}
.drop-shadow-2xl {
  filter: drop-shadow(0 25px 25px hsla(0, 0%, 0%, 0.3));
}
.drop-shadow-none {
  filter: drop-shadow(0 0 #0000);
}
.drop-shadow-ui {
  filter: drop-shadow(0 0 8px hsla(0, 0%, 0%, 0.4))
    drop-shadow(0 0 2px hsla(0, 0%, 0%, 0.7));
}

/* color */
.textGreyA {
  color: var(--greyA);
}
.textGreyB {
  color: var(--greyB);
}
.textGreyC {
  color: var(--greyC);
}
.textGreyD {
  color: var(--greyD);
}
.textGreyE {
  color: var(--greyE);
}
.textGreyF {
  color: var(--greyF);
}
.textGreyG {
  color: var(--greyG);
}
.textGreyH {
  color: var(--greyH);
}
.textGreyI {
  color: var(--greyI);
}

.textSuccess {
  color: var(--success);
}
.textInfo {
  color: var(--info);
}
.textWarning {
  color: var(--warning);
}
.textError {
  color: var(--error);
}

/* background colors */
.bgGreyA {
  background-color: var(--greyA);
}
.bgGreyB {
  background-color: var(--greyB);
}
.bgGreyC {
  background-color: var(--greyC);
}
.bgGreyD {
  background-color: var(--greyD);
}
.bgGreyE {
  background-color: var(--greyE);
}
.bgGreyF {
  background-color: var(--greyF);
}
.bgGreyG {
  background-color: var(--greyG);
}
.bgGreyH {
  background-color: var(--greyH);
}
.bgGreyI {
  background-color: var(--greyI);
}
.bgNone {
  background-color: transparent !important;
}
.bgTransparent {
  background-color: var(--transparentBg);
}

/* border colors */
.borderGreyA {
  border-style: solid;
  border-color: var(--greyA);
  border-width: 2px;
}
.borderGreyB {
  border-style: solid;
  border-color: var(--greyB);
  border-width: 2px;
}
.borderGreyC {
  border-style: solid;
  border-color: var(--greyC);
  border-width: 2px;
}
.borderGreyD {
  border-style: solid;
  border-color: var(--greyD);
  border-width: 2px;
}
.borderGreyE {
  border-style: solid;
  border-color: var(--greyE);
  border-width: 2px;
}
.borderGreyF {
  border-style: solid;
  border-color: var(--greyF);
  border-width: 2px;
}
.borderGreyG {
  border-style: solid;
  border-color: var(--greyG);
  border-width: 2px;
}
.borderGreyH {
  border-style: solid;
  border-color: var(--greyH);
  border-width: 2px;
}
.borderGreyI {
  border-style: solid;
  border-color: var(--greyI);
  border-width: 2px;
}
.borderNone {
  border: none !important;
}

/* Labels 
DON'T rename the following unless you rename the css strings as well.
We use the selector string for these rules to reference them programmatically
They are selected in Bootstrap.tsx and used in Perception3JS.tsx

!!!!IMPORTANT Keep the rules unique or at build time they will be concatenated into one rule
and we will fail programmatically getting a reference to them. !!!!!!
*/

/* Tracked Objects Labels */
.gui-label[data-classification='Prospect'] {
  color: rgb(40, 40, 40);
}
.gui-label[data-classification='Bicycle'] {
  color: rgb(50, 50, 50);
}
.gui-label[data-classification='Cluster'] {
  color: rgb(55, 55, 55);
}
.gui-label[data-classification='Unknown'] {
  color: rgb(60, 60, 60);
}
.gui-label[data-classification='Person'] {
  color: rgb(80, 80, 80);
}
.gui-label[data-classification='Vehicle'] {
  color: rgb(90, 90, 90);
}
.gui-label[data-classification='LargeVehicle'] {
  color: rgb(100, 100, 100);
}

.gui-label[data-classification='Inclusion'] {
  color: rgb(110, 110, 110);
}

.gui-label[data-classification='Exclusion'] {
  color: rgb(120, 120, 120);
}

.gui-label[data-classification='Event'] {
  color: rgb(130, 130, 130);
}

.gui-label .dirty {
  color: var(--warning);
  font-weight: 900;
  font-size: var(--r5);
}
.dirty::before {
  content: '!';
}

/* Range Ring Labels */
.polar-grid {
  color: var(--greyD);
  background-color: var(--greyH);
  border-radius: var(--border-radius);
  padding: 0.25rem;
  font-weight: 600;

  box-shadow: 0px 0px 2px var(--greyI), 0px 0px 4px var(--greyI);
}
/* End of Labels */

.gui-label {
  font-size: var(--r3);
  line-height: 1.75;
  hyphens: none;
  white-space: nowrap;
}
#details {
  position: absolute;
  background-color: var(--transparentBg);
  padding: 0.25rem 0.5rem;
  bottom: 0;
}
.gui-header {
  font-size: var(--r3_5);
}
.gui-row {
  font-family: var(--mono);
  display: flex;
  justify-content: space-between;
}
.gui-key {
  display: flex;
  color: var(--greyD);
}
.gui-value {
  display: flex;
  color: var(--greyB);
}

.frameOfReference {
  position: relative;
}
.frameOfReference #offset {
  transform: translate(0, -50px) scale(2);
}

.gui-label[data-classification='Event'] #occupations {
  transition: opacity ease-in-out 200ms;
  opacity: 0;
}
.gui-label[data-classification='Event'] #occupations.show {
  opacity: 1;
}

.gui-label[data-classification='Inclusion'] div,
.gui-label[data-classification='Exclusion'] div,
.gui-label[data-classification='Event'] div {
  bottom: 24px;
  position: relative;
}

/* Id matches SELECTION_DIV_ID */
#selectionDiv {
  border: 1px solid var(--highlighted);
  position: fixed;
  pointer-events: none;
}

/* style */
body {
  background-color: var(--greyH);
  color: var(--greyC);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--greyB);
}

label {
  color: var(--greyC);
  display: inline-flex;
  align-items: center;
  font-size: var(--r3);
  font-weight: 300;
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  border-bottom: 1px dotted var(--greyC);
}

input,
textarea {
  line-height: 1.75;
  font-size: var(--r4);
  color: var(--greyB);
  background-color: var(--greyI);
}
input:disabled,
textarea:disabled {
  background-color: var(--greyH);
  color: var(--greyD);
  cursor: not-allowed;
}

input:disabled,
textarea:disabled {
  background-color: var(--greyH);
  color: var(--greyD);
  cursor: not-allowed;
}

input[type='text'],
input[type='number'],
textarea {
  border: none;
  text-align: right;
  padding: 0 var(--r2);
}

/* Hide number input arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type='checkbox'] {
  appearance: none;
  width: var(--r4);
  height: var(--r4);
  background-color: var(--greyH);
  margin: 0.125rem var(--r1_5);
}

input[type='checkbox']:checked {
  background-color: var(--greyB);
  border: solid var(--greyI) 0.35rem;
}

input[type='radio'] {
  appearance: none;
  background-color: var(--greyI);
  user-select: none;
  width: var(--r5);
  height: var(--r5);
  cursor: pointer;
  border-radius: 50%;
}
input[type='radio']:checked {
  background-color: var(--greyB);
  border: solid var(--greyI) var(--r1_5);
}

/* New select formatting starts (see /src/app/components/Select.tsx) */
select.new-select {
  appearance: none;
  background-color: transparent;
  line-height: inherit;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  outline: none;
  border: none;
  width: 100%;
  padding: var(--r2) var(--r5) var(--r2) var(--r2);
}
select.new-select::-ms-expand {
  display: none;
}
.select {
  position: relative;
  color: var(--greyC);
  background: var(--greyI);
  font-size: var(--r3_5);
  border: solid 1px var(--greyG);
  border-radius: var(--border-radius);
  cursor: pointer;
  min-width: var(--r24);
  padding: 0;
}
.select-arrow {
  position: absolute;
  right: 0.15rem;
  top: 0.5rem;
}
select.new-select:focus + .focus {
  position: absolute;
  top: -0.5px;
  left: -0.5px;
  right: -0.5px;
  bottom: -0.5px;
  border: solid 1px var(--greyC);
  border-radius: inherit;
}

select.new-select option {
  background: var(--greyF);
  text-align: right;
  cursor: pointer;
}
/* New select formatting ends */

/* Legacy select formatting starts */
select {
  color: var(--greyC);
  background-color: var(--greyI);
  padding: var(--r2);
  font-size: var(--r3_5);
  border-color: var(--greyH);
  cursor: pointer;
  min-width: 7rem;
}

select option {
  background-color: var(--greyF);
  text-align: right;
}

option:hover {
  background-color: var(--greyF);
  padding: var(--r4);
}
/* Legacy select formatting ends */

br {
  display: block;
  margin: var(--r2) 0 var(--r2) 0;
  content: ' ';
}
/* 'bgGreyG text-sm px-1 py-2 my-2 borderGreyE grow' */
button {
  font-family: var(--sans);
  color: var(--greyB);
  background-color: var(--greyI);
  padding: var(--r3) var(--r3);
  border: none;
  border-radius: var(--border-radius);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  font-size: var(--r3_5);
  max-width: var(--r80);
}
button:disabled {
  color: var(--greyE);
  cursor: not-allowed;
  background-color: transparent;
  border: solid 1px var(--greyG);
  padding: calc(var(--r3) - 1px) calc(var(--r3) - 1px);
}
button:hover:enabled {
  color: var(--greyA);
  background-color: var(--greyG);
  cursor: pointer;
}
button:active:enabled {
  color: var(--greyB);
  background-color: var(--greyH);
}
button.action:focus:enabled {
  outline: none;
  border: solid 1px var(--greyD);
  padding: calc(var(--r3) - 1px) calc(var(--r3) - 1px);
}

/* Warning Banner */
.warningBanner {
  position: relative;
  background-color: var(--critical);
  width: 100%;
  height: var(--r10);
  border-radius: var(--r1);
  color: var(--greyA);
  font-size: 0.9rem;
  line-height: 2rem;
  padding: var(--r1);
  padding-left: var(--r3);
  cursor: pointer;
  transition: transform 0.1s, box-shadow 0.1s, background-color 0.1s;
}
.warningBanner:hover {
  transform: translateY(-3px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.6);
  background-color: var(--criticalHover);
}
button.close {
  background-color: transparent;
  border: none;
  border-radius: var(--r1);
  width: var(--r4);
  height: var(--r4);
  font-weight: 500;
  font-size: var(--r4);
  line-height: var(--r4);
  color: var(--greyA);
}
button.close:hover {
  background-color: rgba(115, 10, 10, 0.2);
}

button svg {
  fill: var(--greyD);
}
button svg.active {
  fill: var(--greyB);
}
button:disabled svg {
  fill: var(--greyF);
}
button:enabled:hover svg {
  fill: var(--greyA);
}

button svg.VisibilityIcon {
  fill: var(--greyE);
}
button svg.VisibilityIcon.active {
  fill: var(--greyC);
}
button:disabled svg.VisibilityIcon {
  fill: var(--greyF);
}
button:enabled:hover svg.VisibilityIcon {
  fill: var(--greyB);
}

button:disabled svg.UpDownIcon {
  fill: var(--greyE);
}

button.action {
  margin: var(--r1_5);
}

/* Abbreviation popups - used for click to copy */
abbr[title] {
  transition: filter 0s ease-in-out;
}

abbr[title]:not(:hover),
abbr[title]:not(.enabled) {
  border-bottom: none !important;
  cursor: inherit !important;
  text-decoration: none !important;
}

abbr[title].enabled:active {
  filter: brightness(1.5);
}
abbr[title].enabled:not(:active) {
  transition: filter 0.25s ease-in-out;
}

/* under the sun */
.Light svg.Logo {
  fill: black;
}

.Light .text-xxs {
  font-size: var(--r4);
}
.Light .text-xs {
  font-size: var(--r4);
}
.Light .text-sm {
  font-size: var(--r4);
}
.Light .text-base {
  font-size: var(--r4);
}
.Light .text-base {
  font-size: var(--r5);
}
.Light .text-lg {
  font-size: var(--r6);
}
.Light .text-xl {
  font-size: var(--r7);
}
.Light .text-2xl {
  font-size: var(--r8);
}
.Light .text-3xl {
  font-size: var(--r10);
}

.Light {
  --greyA: var(--secIndigo);
  --greyB: hsl(0 0% 0%);
  --greyC: hsl(0, 0%, 10%);
  --greyD: hsl(0 0% 25%);
  --greyE: hsl(0 0% 30%);
  --greyF: hsl(0 0% 65%);
  --greyG: hsl(0 0% 70%);
  --greyH: hsl(0 0% 88%);
  --greyI: hsl(0 0% 91%);
  --greyJ: hsl(0 0% 95%);

  font-weight: 500;

  --info: #324ac4;
  --success: #afda39;
  --warning: #f89f2b;
  --error: #d62a52;

  --critical: #db4644;
  --criticalHover: #e85351;

  --transparentBg: hsla(0, 0%, 100%, 0.365);
}

.Light .drop-shadow-ui {
  filter: drop-shadow(0 0 3px hsla(0, 0%, 100%, 0.6))
    drop-shadow(0 0 1px hsla(0, 0%, 100%, 0.8));
}
