/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

input[type='range'] {
  -webkit-appearance: none;
  margin: 0;
  width: 100%;
  background: transparent;
  cursor: pointer;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 4px;
  height: 20px;
  background: var(--greyC);
  cursor: pointer;
}

input[type='range']:focus::-webkit-slider-thumb {
  background: var(--greyB);
}
