/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/
 
.floating-input {
  position: relative;
  margin-top: var(--r6);
  margin-bottom: var(--r2);
}
.floating-input .input:focus ~ .label,  /* Input has focus */
.floating-input .input:not(:placeholder-shown) ~ .label  /* Input has a value */ {
  transform: translateY(-35px) translateX(calc(-1 * var(--r2))) scale(0.75);
  transition: transform 200ms, color 200ms;
}

.floating-input .input:not(:placeholder-shown) ~ .label {
  color: var(--greyD);
  fill: var(--greyD);
}
.floating-input .input:focus ~ .label {
  color: var(--greyC);
  fill: var(--greyC);
}

.floating-input .input {
  height: 100%;
  width: 100%;
  background-color: var(--greyI);
  border-radius: 5px;
  box-sizing: border-box;
  outline: 0;
  padding: var(--r2) var(--r2);
}

.floating-input .input:disabled {
  background-color: var(--greyH);
  color: var(--greyD);
  cursor: not-allowed;
}

.floating-input .placeholder {
  color: var(--greyD);
  fill: var(--greyD);
  font-family: sans-serif;
  /* match left padding */
  left: var(--r2);
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
}
