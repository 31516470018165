/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

.labelsContainer {
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

#Layout {
  display: grid;
  grid-template-areas:
    'header header header'
    'pane-left image pane-right'
    'pane-left content pane-right'
    'pane-left timeline pane-right'
    'footer footer footer';
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto 1fr auto;
  height: 100vh;
  overflow: hidden;
}

#Header {
  z-index: 20;
  grid-area: header;
}

#Footer {
  grid-area: footer;
}

#Pane-left {
  grid-area: pane-left;
  overflow-y: hidden;
}

#Pane-right {
  grid-area: pane-right;
  overflow-y: hidden;
}

#Image2D {
  grid-area: image;
  overflow: hidden;
  resize: vertical;
  max-height: 30vh;
}

#Timeline {
  grid-area: timeline;
}

#Content {
  position: relative;
  touch-action: none;
  flex: 1;
  grid-area: content;
}

.modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  z-index: 3;
  background-color: hsl(0deg 0% 0% / 20%);
}

canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  box-shadow: inset 0px 0px 10px 0px hsl(0deg 0% 0% / 10%);
}
